import { LazyQueryExecFunction } from "@apollo/client";
import {
  Address,
  Exact,
  GetOfficesBySearchQuery,
  useCreateOfficeMutation,
  useGetOfficesBySearchLazyQuery,
} from "graphql/queries/generated/queries";
import useStagger from "hooks/useStagger";
import { handleMutation } from "middleware/Toaster";
import { useTranslation } from "react-i18next";

const useGetOrCreateOffice = () => {
  const [BROKEN_searchOffice] = useGetOfficesBySearchLazyQuery();
  const searchOffice: LazyQueryExecFunction<
    GetOfficesBySearchQuery,
    Exact<{
      search: string;
      registryId: string;
    }>
  > = useStagger(BROKEN_searchOffice);
  const { t } = useTranslation();
  const [createOffice] = useCreateOfficeMutation({
    ...handleMutation({ successMessage: t("Office created!") }),
  });

  const doUseGetOrCreateOffice = async (search: string, registryId: string, address?: Address) => {
    if (!search || search?.trim() === "") return undefined;
    const { data: searchOfficeData, error: searchOfficeError } = await searchOffice({
      variables: {
        search,
        registryId,
      },
    });
    if (!searchOfficeData?.offices || searchOfficeData.offices.length === 0) {
      const { data: createOfficeData, errors: createOfficeError } = await createOffice({
        variables: {
          input: {
            officeData: {
              name: search,
              officeAddress: address,
              active: true,
              registry: registryId,
              main: false,
            },
          },
        },
      });

      if (createOfficeError) throw new Error(createOfficeError[0]?.message);
      const {
        createOffice: { office },
      } = createOfficeData;
      return office.id;
    }

    const { offices } = searchOfficeData;

    return offices[0].id as string;
  };

  return doUseGetOrCreateOffice;
};

export default useGetOrCreateOffice;
