import {
  useGetUniqueIrrelevantRegistryLazyQuery,
  useCreateRegistryMutation,
} from "graphql/queries/generated/queries";
import { handleMutation } from "middleware/Toaster";

const useGetOrCreateIrrelevantRegistry = () => {
  const [getIrrelevant] = useGetUniqueIrrelevantRegistryLazyQuery();
  const [createRegistry] = useCreateRegistryMutation({
    ...handleMutation({ successMessage: "Registry created!" }),
  });

  const getOrCreateIrrelevant = async () => {
    const { data: getIrrelevantData, error: getIrrelevantError } = await getIrrelevant();
    if (getIrrelevantData.allRegistries.edges.length === 0) {
      const { data: createRegistryData, errors: createRegistryErrors } = await createRegistry({
        variables: {
          input: {
            registryData: {
              fullName: "Irrelevant",
              isCompany: false,
              isPublic: true,
              isIrrelevantTenantDefault: true,
            },
          },
        },
      });

      if (createRegistryErrors) throw new Error("Unable to create anonymous registry entry!");
      const {
        createRegistry: { registry },
      } = createRegistryData;

      return { label: registry.fullName, value: registry.id };
    }

    if (getIrrelevantError) throw new Error("Unable to retrieve anonymous registry entry!");
    const {
      allRegistries: { edges },
    } = getIrrelevantData;
    const anonymousRegistry = edges[0]?.node;
    return { label: anonymousRegistry.fullName, value: anonymousRegistry.id };
    // return {value: registry.id, label:  registry.fullName}
  };

  return getOrCreateIrrelevant;
};

export default useGetOrCreateIrrelevantRegistry;
